<!--
    Created by 程雨喵'mac on 2024/11/28.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    页面名称：长期离线报表
-->
<style lang="less">
#offline-long-time-analysis {

}
</style>

<template>
  <div id="offline-long-time-analysis">
    <yg-split-vertical-box
      v-model="isOpen"
      :show-open-button="tableData.length > 0"
    >
      <div
        slot="left"
        class="split-box"
      >
        <!-- 查询条件 -->
        <div v-permission="'system:statis:longTermOfflineList:list'">
          <yg-search-area
            :show-info="false"
            :value="searchOption"
            :is-stealth="true"
            :show-rest="false"
            @on-search="_clickSearch"
            @on-change-stealth="tableResizeFlag = !tableResizeFlag"
          />
        </div>
        <yg-simple-table
          v-permission="'system:statis:longTermOfflineList:list'"
          :control-resize="tableResizeFlag"
          :top-columns="tableTopColumns"
          :top-title="topTitle"
          :columns="tableColumns"
          :export-condition="settingExportCondition"
          :export-setting="settingExportItem"
          :data="tableData"
          :page="page"
          @on-page-change="onChangePage"
        />
      </div>
      <div
        slot="right"
        class="right-box"
      >
        <v-charts-analysis
          v-if="isOpen"
        />
      </div>
    </yg-split-vertical-box>
  </div>
</template>

<script>
import DataStatisticsApiSet from '@/request/api/DataStatisticsApiSet';
import { OfflineLongTimeAnalysisDataModel } from './model/OfflineLongTimeAnalysisDataModel';
import ColumnMixin from './mixin/tableColumn';
import vChartsAnalysis from '@/pages/106__dataStatistics/_components/charts/index.vue';

export default {
  components: { vChartsAnalysis },
  mixins: [ColumnMixin],
  data () {
    return {
      searchOption: {
        status: BaseSearchModel.initData('启用状态', 'status', 'Select').setData([
          { value: 1, label: '已启用' },
          { value: 0, label: '已停用' }
        ]),
        officeCode: BaseSearchModel.initData('吾行办事处', 'officeCode', 'YgGlobalSelect').setData('officeList'),
        region: BaseSearchModel.initData('省/市/区', 'region', 'YgRegionCascader').setData(2),
        communityName: BaseSearchModel.initData('小区名称', 'communityName', 'Input'),
        duration: BaseSearchModel.initData('累计离线时长', 'duration', 'YgRangeInput').setDefault(['1440', '']).setIsNumberRange([1440, 999999]).setUnit('分钟')
      },
      settingExportCondition: {},
      settingExportItem: BaseSettingModel.initData('导出列表', 'download', 'md-cloud-download').setExport().setExportReportType(39).setPermission('system:statis:longTermOfflineList:export'),
      isOpen: false,
      page: { current: 1, total: 0 },
      tableData: [],
      // table重新渲染
      tableResizeFlag: false
    };
  },
  computed: {
    topTitle () {
      if (this.page.total) {
        return `长期离线设备(设备总数量:${this.page.total})`;
      }
      return '长期离线设备';
    }
  },
  created () {},
  mounted () {
    console.log(this.$el.clientWidth);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {},
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const s = this.searchOption;
      const api = DataStatisticsApiSet.getLongTermOfflineList;
      api.params = {
        accumulateOfflineBeginTime: s.duration.value[0] || '',
        accumulateOfflineEndTime: s.duration.value[1] || '',
        communityName: s.communityName.value,
        status: s.status.value,
        officeCode: s.officeCode.value,
        province: s.region.value[0] || '',
        city: s.region.value[1] || '',
        district: s.region.value[2] || '',
        pageNo: this.page.current,
        pageSize: 200
      };
      this.tableData = [];
      this.page.total = 0;

      this.settingExportCondition = api.params;

      this.$http(api).then(res => {
        const resData = res.data;
        this.page.total = resData.total;
        this.tableData = OfflineLongTimeAnalysisDataModel.initListWithDataList(resData.rows);
      });
      // setTimeout(() => {
      //   const resList = OfflineLongTimeAnalysisDataModel.createFakeDataList(200);
      //   this.page.total = resList.length * 2;
      //   this.tableData = OfflineLongTimeAnalysisDataModel.initListWithDataList(resList);
      // }, 300);
    }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>


export default {
  data () {
    return {
      // 下面数据明细-表头第一层
      tableTopColumns: [
        BaseTableColumn.initData('', '设备信息及状态').setH5ColSpan(4),
        BaseTableColumn.initData('', '离线信息').setH5ColSpan(2),
        BaseTableColumn.initData('', '小区及位置信息').setH5ColSpan(5)
      ],
      // 下面数据明细-表头第二层
      tableColumns: [
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('deviceCode', '设备编码', 60),
        BaseTableColumn.initData('name', '设备名称', 60),
        BaseTableColumn.initData('statusDesc', '设备启用状态', 40),
        BaseTableColumn.initData('createTime', '开始时间', 40),
        BaseTableColumn.initData('offlineDuration', '累计时长(分钟)', 40),
        BaseTableColumn.initData('communityName', '小区名称', 40),
        BaseTableColumn.initData('provinceName', '省份', 40),
        BaseTableColumn.initData('cityName', '城市', 40),
        BaseTableColumn.initData('districtName', '区域', 40),
        BaseTableColumn.initData('officeName', '办事处名称', 40)
      ]
    };
  }
};

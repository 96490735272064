import BaseModel from '@/libs/base/dataModel/BaseModel';

export class OfflineLongTimeAnalysisDataModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    // 设备编号
    this.deviceCode = '';
    // 设备名称
    this.name = '';
    // 设备启用状态（0-禁用 1-启用）
    this.status = '';
    // 设备启用状态描述
    this.statusDesc = '';
    // 离线开始时间
    this.createTime = '';
    // 离线时长
    this.offlineDuration = '';
    // 小区名称
    this.communityName = '';
    // 省名称
    this.provinceName = '';
    // 市名称
    this.cityName = '';
    // 区名称
    this.districtName = '';
    // 办事处名称
    this.officeName = '';
    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    this.offlineDuration = resData.offlineDuration - 0;
    return this;
  }

  static createFakeDataList (len = 30) {
    const list = [];
    const statusArr = ['禁用', '启用'];
    for (let i = 0; i < len; i++) {
      const dic = {
        deviceCode: 'KHJKHJKHKHK' + BaseModel.getFakeNumber().toFixed(0),
        name: BaseModel.getFakeTitle(8),
        status: i % 2,
        statusDesc: statusArr[i % 2],
        createTime: '2024-12-0' + (i + 1),
        offlineDuration: BaseModel.getFakeNumber().toFixed(0),
        communityName: BaseModel.getFakeTitle(8),
        provinceName: BaseModel.getFakeTitle(3),
        cityName: BaseModel.getFakeTitle(3),
        districtName: BaseModel.getFakeTitle(3),
        officeName: BaseModel.getFakeTitle(8)
      };
      list.push(dic);
    }
    return list;
  }
}
